import { gql } from '../types/generated/gql';

export const VIEWING_POLICY_FRAGMENT = gql(`
  fragment ViewingPolicyFields on ViewingPolicy {
    id
    href
    action
    actionLabel
    name
    audienceHref
  }
`);
